export const FETCH_ALL_USER_TXNS = `query ($pageSize:Int,$pageNumber:Int,$status:String) {
  list_all_transactions(page_size:$pageSize,page_number:$pageNumber,status:$status){
   message
   data {
     _id
     txn_id
     txn_signature
     type
     amount
     status
     created_at
     chain_network
     currency
     from_user_wid
     to_user_wid
     to_uid {
       _id
       name
       email
     }
     from_uid {
       _id
       name
       email
     }
   }
   hasMore
 }
 }
 `;

export const FETCH_USER_PROFILE = `{
  user_profile {
    message
    data {
      _id
      name
      email
      img
      user_name
      wallets {
        _id
        wallet_address
        balance
        network
      }
    }
  }
}
`;

export const TRANSFER_TO_EMAIL = `mutation ($email: String!, $network: String, $amount: Float!) {
  send_crypto_to_email(email: $email, network: $network, amount: $amount) {
    message
  }
}
`;

export const TRANSFER_TO_WALLET = `mutation ($walletAddress: String!, $network: String, $amount: Float!) {
  send_crypto_to_wallet(wallet_address: $walletAddress, network: $network, amount: $amount) {
    message
  }
}
`;

export const FETCH_WALLET_BALANCE = `query ($network: String!) {
  list_wallet_balance(network: $network) {
    message
    data {
      wallet_address
      balance
      network
    }
  }
}
`;

export const SHARE_QR_LINK = `query ($email: String!) {
  share_qr_link(email: $email) {
    message
  }
}
`;

export const FETCH_USER_DETAILS = `query ($id: String!) {
  list_user_details(_id: $id) {
    message
    data {
      _id
      name
      email
      img
      wallets {
        wallet_address
        network
      }
    }
  }
}
`;

export const FETCH_BLOCKCHAIN_TXN_HISTORY = `query ($network: String, $pageSize: Int, $pageNumber: Int) {
  list_blockchain_txn_history(network: $network, page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      blockNumber
      timeStamp
      hash
      nonce
      blockHash
      transactionIndex
      from
      to
      value
      gas
      gasPrice
      isError
      txreceipt_status
      input
      contractAddress
      cumulativeGasUsed
      gasUsed
      confirmations
      methodId
      functionName
      status
      token_address
      token_symbol
    }
  }
}
`;

export const UPDATE_USER_PROFILE = `mutation ($name: String!) {
  update_user_profile(name: $name) {
    message
  }
}
`;

export const CHECK_SECURO_USER_ID = `query{
  check_securo_user_id {
    securo_status
  }
}`;

export const CREATE_SECURO_CUSTOMER = `mutation ($data: create_securo_customer_data) {
  create_securo_customer(data: $data){
    message
    data {
      user_id
    }
  }
}`;

export const CREATE_SECURO_PAYMENT_REQUEST = `mutation ($data: create_securo_pmt_request_data) {
  create_securo_pmt_request(data: $data){
    message
    data {
      url
    }
  }
}`;

export const FETCH_BEST_SWAP_ROUTES = `query ($from: String!, $to: String!, $amount: Float!) {
  fetch_best_swap_routes(from: $from, to: $to, amount: $amount) {
    message
    data {
      inAmount
      outAmount
      priceImpactPct
      amount
      slippageBps
      otherAmountThreshold
      swapMode
      marketInfos {
        id
        label
        inputMint
        outputMint
        inAmount
        outAmount
        priceImpactPct
        notEnoughLiquidity
        lpFee {
          amount
          mint
          pct
        }
        platformFee {
          amount
          mint
          pct
        }
      }
    }
  }
}
`;

export const SWAP_COINS = `mutation ($inAmount: Float!, $outAmount: Float!, $priceImpactPct: Float!, $amount: Float!, $slippageBps: Float!, $otherAmountThreshold: Float!, $swapMode: String!, $marketInfos: [swap_coins_marketInfos]!) {
  swap_coins(inAmount: $inAmount, outAmount: $outAmount, priceImpactPct: $priceImpactPct, amount: $amount, slippageBps: $slippageBps, otherAmountThreshold: $otherAmountThreshold, swapMode: $swapMode, marketInfos: $marketInfos) {
    message
  }
}
`;

export const CREATE_GUARDIAN_PAYMENT_REQUEST = `mutation ($data: create_guardian_pmt_req_data) {
  create_guardian_pmt_req(data: $data) {
    message
    data {
      url
    }
  }
}
`;
