import styled from "styled-components";

export const EmptyBox = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    background-color: rgb(16, 20, 39, 0.8);
    border-radius: 10px;
    font-weight: bold;
`;

export const Title = styled.div`
    margin-top: 50px;
    text-align: center;
    i {
        font-size: 10px;
        padding: 0px 10px;
    }
`;
