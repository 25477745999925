import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { Button, Box } from "@material-ui/core";

import { resetPaymentReqDetails, listPaymentReqDetails } from "store/actions";
import { Stack } from "@mui/system";
import { Card, Typography } from "@mui/material";
import { getBlockchainNetwork } from "utils";

const styles = {
    card: {
        p: 3, 
        color: "#666",
        borderRadius: "10px", 
    },
    label: {
        textTransform: 'uppercase', 
        fontSize: "12px",
    },
    databox: {
        p: 1, 
        border: "1px solid #ccc", 
        borderRadius: "5px", 
        textAlign: "right",
        boxShadow: "0 2px 2px rgb(0 0 0 / 0.2)",
    },
    amountData: {
        p: 1, 
        mb: 1,
        width: "100%", 
        bgcolor: "#f0f0f0", 
        borderRadius: "10px",
    },
    iconBox: {
        p: "15px", 
        width: "70px", 
        bgcolor: "#fff", 
        borderRadius: "5px", 
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    }
}

const PaymentRequestDetails = ({ reqId, dispatch, handlePage, updateToken }) => {
    const { data } = useSelector(({ paymentReqs: { details } }) => ({
        data: details,
    }));

    useEffect(() => {
        dispatch(resetPaymentReqDetails());
        dispatch(listPaymentReqDetails({ reqId: reqId }));
    }, []);

    const updateDetails = () => {
        updateToken({ email: data?.to_uid?.email ?? "", currency: data?.currency });
        handlePage(2);
    };

    return (
        <>
            <Card sx={styles.card}>
                <Stack spacing={2}>
                    <Box sx={styles.amountData}>
                        <Stack direction="row" spacing={1}>
                            <Box sx={styles.iconBox}>
                                <img src={getBlockchainNetwork(data?.currency)} alt="..."/>
                            </Box>
                            <Stack sx={{width: "100%"}} alignItems="flex-end" justifyContent="center">
                                <Typography variant="subtitle2">
                                    <b>Amount</b>
                                </Typography>
                                <Typography color={"primary"} variant="h6">
                                    <b>
                                        {data?.amount ?? "-"} {data?.currency ? data?.currency.toUpperCase() : ""}
                                    </b>
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Request from</b>
                        </Typography>
                        <Box sx={styles.databox}>
                            <Typography variant="body2"><b>{data?.from_uid ? data?.from_uid?.name : "-"}</b></Typography>
                            <Typography variant="body2"><b>{data?.from_uid ? data?.from_uid?.email : "-"}</b></Typography>
                        </Box>
                    </Stack>
                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Request ID</b>
                        </Typography>
                        <Box sx={styles.databox}>
                            <Typography variant="body2"><b>{data?.req_id ?? "-"}</b></Typography>
                        </Box>
                    </Stack>
                   
                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Requested At</b>
                        </Typography>
                        <Box sx={styles.databox}>
                        <Typography variant="body2">
                            {data?.created_at
                                ? moment.unix(data?.created_at).format("DD-MM-YYYY hh:mm A")
                                : "-"}
                        </Typography>
                        </Box>
                    </Stack>
                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Status</b>
                        </Typography>
                        <Box sx={styles.databox}>
                        <Typography variant="body2">{data?.status ?? "-"}</Typography>
                        </Box>
                    </Stack>
                    {data?.status === "pending" ? (
                        <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
                            {/* <Button fullWidth variant="outlined" onClick={() => handleSubmit("reject")}>
                                Reject
                            </Button> */}
                            <Button fullWidth  variant="contained" onClick={() => updateDetails()}>
                                Continue
                            </Button>
                        </Stack>
                    ) : null}
                </Stack>
            </Card>
        </>
    );
};

export default PaymentRequestDetails;
