export const guardarianCryptoCurrencies = [
    {
        code: "SOL",
        name: "Solana",
        network: "SOL",
        display_chain: "Solana chain",
    },
    {
        code: "USDC",
        name: "USDC",
        network: "SOL",
        display_chain: "Solana chain",
    },
];
