import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";

import { qrRoutes } from "routes.js";
import componentStyles from "assets/theme/layouts/auth.js";
import styled from "styled-components";

const useStyles = makeStyles(componentStyles);

const Outer = styled.div`
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(#000, #222831, #000);
`;

const Inner = styled.div`
    padding-top: 50px;
    color: #cad7fa;
    font-family: "Jost", sans-serif;
`;

const QR = () => {
    const classes = useStyles();
    const location = useLocation();

    const { loading } = useSelector(({ loading }) => ({
        loading,
    }));

    React.useEffect(() => {
        document.body.classList.add(classes.bgDefault);
        return () => {
            document.body.classList.remove(classes.bgDefault);
        };
    });
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/") {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return <Redirect to={"/landing"} key={key} />;
            }
        });
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="primary" />
            </Backdrop>
            {/* <Outer> */}
            <Inner>
                <Switch>
                    {getRoutes(qrRoutes)}
                    <Redirect from="*" to="/landing" />
                </Switch>
            </Inner>
            {/* </Outer> */}
        </>
    );
};

export default QR;
