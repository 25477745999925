import React, { useState } from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import { Stack } from "@mui/material";

import Securo from "./securo";
import Guardarian from "./guardarian";

function BuyNow() {
    const [buyUsing, setBuyUsing] = useState("guardarian");

    const handleTabs = (event, newValue) => {
        setBuyUsing(newValue);
    };

    return (
        <div>
            <Box sx={{ width: "100%", mt: 6 }}>
                <Stack>
                    <Tabs value={buyUsing} onChange={handleTabs} centered>
                        <Tab value={"guardarian"} label="Buy Using Guardarian" />
                        <Tab value={"securo"} label="Buy Using Securo" />
                    </Tabs>
                </Stack>
            </Box>
            {buyUsing === "securo" ? <Securo /> : <Guardarian />}
        </div>
    );
}

export default BuyNow;
