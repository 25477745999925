import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box, Button, FilledInput, FormControl, FormLabel, MenuItem, Select } from "@material-ui/core";
import { Stack } from "@mui/material";
// import { cryptoCurrencies } from "datas/crypto-currencies";
// import { fiatCurrencies } from "datas/fiat-currencies";
// import { createRampayOrder, resetRampayOrderId } from "store/actions";
import { guardarianFiatCurrencies } from "datas/guardarianFiatCurrencies";
import { createGuardianPaymentRequest } from "store/actions";
import { guardarianCryptoCurrencies } from "datas/guardarianCryptoCurrencies";

// const API_KEY = process.env.REACT_APP_RAMPAY_API_KEY;

function BuyingPage({ dispatch }) {
    const [wallet, setWallet] = useState("");
    const [crypto, setCrypto] = useState("USDC");
    const [fiat, setFiat] = useState("USD");
    const [fromAmount, setFromAmount] = useState(50);

    const { userProfile } = useSelector(({ user: { profile } }) => ({
        userProfile: profile,
    }));

    useEffect(() => {
        setWallet(
            userProfile?.wallets ? userProfile.wallets.filter((e) => e.network === "solana")[0]?.wallet_address ?? "" : ""
        );
    }, []);

    const handleCryptoChange = (event) => {
        setCrypto(event.target.value);
    };

    const handleFiatChange = (event) => {
        setFiat(event.target.value);
    };

    const handleAmountChange = (event) => {
        setFromAmount(event.target.value);
    };

    const handleContinue = async () => {
        if (fromAmount < 50) {
            return toast.error(`Minimum amount is 30`);
        }

        const data = {
            payout_address: wallet,
            from_currency: fiat,
            from_amount: parseFloat(fromAmount),
            to_currency: crypto,
            to_network: "SOL",
        };

        let url = await dispatch(createGuardianPaymentRequest({ data: data }));
        if (url) {
            window.open(url.url);
        } else {
            return;
        }
    };

    return (
        <>
            <Box sx={{ p: 3 }}>
                {/* <div style={{ marginBottom: "10px", textAlign: "center", fontSize: "18px", color: "#000" }}>
                    <p>Buy Here</p>
                </div> */}
                <Stack spacing={2}>
                    <Stack>
                        <FormLabel>Select Crypto Currency</FormLabel>
                        <FormControl fullWidth>
                            <Select fullWidth size="small" value={crypto} onChange={handleCryptoChange}>
                                {guardarianCryptoCurrencies.map((e, i) => (
                                    <MenuItem value={e.code} key={i}>
                                        {e.code} ({e.display_chain})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormLabel>Select Fiat Currency</FormLabel>
                        <FormControl fullWidth>
                            <Select fullWidth size="small" value={fiat} onChange={handleFiatChange}>
                                {guardarianFiatCurrencies.map((e, i) => (
                                    <MenuItem value={e.ticker} key={i}>
                                        {e.name} ({e.ticker})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormLabel>Fiat Amount</FormLabel>
                        <FilledInput value={fromAmount} onChange={handleAmountChange} />
                    </Stack>

                    <Stack>
                        <Button variant="contained" onClick={handleContinue}>
                            Continue
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </>
    );
}

export default BuyingPage;
