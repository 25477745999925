import React from "react";

import { Button } from "@material-ui/core";
import "./style.css";

import { history } from "utils";
import NavBar from "./navBar";

const buttonStyle = {
    backgroundColor: "#B5EAD7",
    color: "black",
    border: 0,
};

function Landing() {
    return (
        <div>
            <NavBar />

            <section className="container-fluid banner-container">
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-md-6 banner-txt">
                            <h1>Crypto wallet with social login, built to simplify crypto payments</h1>
                            <p>Signup and access wallet with email</p>
                            <p>Go live in minutes, no KYC required</p>
                            <p>Multi-Chain, Multi-Crypto</p>
                            <p>Merchant account facility available</p>
                            <p>Sent crypto to an email</p>
                            <p>Receive payment request in your wallet</p>
                            <p>Onramp-Offramp (Launching Soon)</p>
                            <div className="btn-row row">
                                <Button
                                    className="btn btn-primary clr"
                                    style={buttonStyle}
                                    onClick={() => history.push("/auth/home")}
                                >
                                    Login
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-6 banner-img">
                            <img src={require("../../assets/bg/crypto-portfolio.svg").default} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Landing;
