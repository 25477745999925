import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listBlockchainTxnHistory, resetBlockchainTxnHistory } from "store/actions";
// import CustomTable from "components/CustomTable/CustomTable";
import { EmptyBox } from "components/StyledComponents";
import ExpandableListView from "components/ExpandableListView/ExpandableListView";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import moment from "moment";
import { getBlockchainNetwork } from "utils";

function TabTwo() {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(1);


    const handleTabs = (event, newValue) => {

        setTab(newValue);
    };

    const { blockchainTxns, showViewMore } = useSelector(({ user: { blockchainTxns } }) => ({
        blockchainTxns: blockchainTxns.list,
        showViewMore: blockchainTxns.showViewMore,
    }));
console.log(blockchainTxns);
    useEffect(() => {
        dispatch(resetBlockchainTxnHistory());
        fetchBlockchainTxns();
    }, [tab]);

    const fetchBlockchainTxns = () => {
        dispatch(listBlockchainTxnHistory({ network: tab === 2 ? "solana" : "solana_token" }));
    };

    return (
        <>
            <Stack>
                <Tabs
                    value={tab || 1}
                    onChange={handleTabs}
                    centered
                >
                    <Tab style={{ color: "#bababa" }} value={1} label="Tokens" />
                    <Tab style={{ color: "#bababa" }} value={2} label="Solana" />
                </Tabs>
            </Stack>

            {blockchainTxns?.length ? (
                <>
                    {tab === 2 ?
                        <ExpandableListView
                            pairs={[
                                { key: "Hash", value: "hash" },
                                { key: "From", value: "from" },
                                { key: "To", value: "to" },
                                {
                                    key: "Value",
                                    render: (rowData) => `${rowData?.value} SOL`,
                                },
                                {
                                    key: "Date",
                                    render: (rowData) => `${moment(rowData?.timeStamp).format("DD-MM-YYYY hh:mm A")}`,
                                },
                            ]}
                            leftTitle={[
                                {
                                    key: "value",
                                    render: (rowData) => `${rowData?.value} SOL`,
                                },
                                {
                                    key: "timeStamp",
                                    render: (rowData) => `${moment(rowData?.timeStamp).format("DD-MM-YYYY hh:mm A")}`,
                                }
                            ]}
                            data={blockchainTxns}
                            iconImg={{render: () => `${getBlockchainNetwork("SOL")}`}}
                            showViewMoreButton={blockchainTxns.length > 9 ? showViewMore : null}
                            viewMoreButtonClick={fetchBlockchainTxns}
                        /> :
                        <ExpandableListView
                            pairs={[
                                {
                                    key: "Value",
                                    render: (rowData) => (
                                        <Typography>
                                            <b>{`${rowData?.value} ${rowData?.token_symbol ?? ""}`}</b>
                                        </Typography>
                                    )
                                },
                                { key: "Date", value: "timeStamp" },
                                { key: "Hash", value: "hash" },
                                { key: "From", value: "from" },
                                { key: "To", value: "to" },
                                { key: "Token", value: "token_address" },
                            ]}
                            leftTitle={[
                                {
                                    key: "value",
                                    render: (rowData) => (
                                        <Typography>
                                            <b>{`${rowData?.value} ${rowData?.token_symbol ?? ""}`}</b>
                                        </Typography>
                                    )
                                },
                                {
                                    key: "timeStamp",
                                    value: "timeStamp",
                                }
                            ]}
                            data={blockchainTxns}
                            iconImg={{render: (e) => `${getBlockchainNetwork(e?.token_symbol)}`}}
                            showViewMoreButton={blockchainTxns.length > 9 ? showViewMore : null}
                            viewMoreButtonClick={fetchBlockchainTxns}
                        />
                    }
                </>

                // <CustomTable
                //     columns={[
                //         { title: "Hash", field: "hash" },
                //         { title: "From", field: "from" },
                //         { title: "To", field: "to" },
                //         { title: "Value", field: "value" },
                //     ]}
                //     data={blockchainTxns}
                // />
            ) : (
                <EmptyBox>No data found</EmptyBox>
            )}
        </>
    );
}

export default TabTwo;
