import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import SendRequest from "./sendRequest";

function NewRequest({ handleModal }) {
    return (
        <>
            <Grid item>
                <Card style={{ borderRadius: "20px", backgroundColor: "#181529", boxShadow: "0 3px 70px #000000" }}>
                    <CardContent style={{ padding: "30px 50px 20px" }}>
                        <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "white" }}>
                            <p>New Payment Request</p>
                        </div>
                        <br />
                        <SendRequest handleModal={handleModal} />
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default NewRequest;
