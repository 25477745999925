import React from "react";
import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import styled from "styled-components";
import ShareQr from "./shareQr";
import { useParams } from "react-router-dom";

const BoxDiv = styled.div`
    background-color: #181529;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    outline: none;

    @media screen and (max-width: 600px) {
        width: 90%;
    }
`;

const ReceivePage = () => {
    const { username } = useParams();

    return (
        <>
            <Box>
                <BoxDiv>
                    <Stack sx={{ p: 3 }}>
                        <Stack spacing={2}>
                            <div style={{ textAlign: "center", fontSize: "17px", color: "white" }}>
                                <p>SEND QR CODE VIA EMAIL</p>
                            </div>
                            <Stack>
                                <ShareQr userName={username} />
                            </Stack>
                        </Stack>
                    </Stack>
                </BoxDiv>
            </Box>
        </>
    );
};

export default ReceivePage;
