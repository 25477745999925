import React, { useEffect, useState } from 'react';
import { FilledInput, FormControl, FormLabel, MenuItem, Select, Button } from "@material-ui/core";
import { Box, Chip, Stack } from "@mui/material";
import { fetchWalletBalance } from 'store/actions';
import { getBlockchainName } from 'utils';
import { fetchBestSwapRoutes } from 'store/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { swapCoins } from 'store/actions';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    backgroundColor: "#181529",
    color: "#fff",
};
const resWidth = {
    width: {
        lg: "430px",
        md: "430px",
        sm: "430px",
        xs: "90%"
    }
};

const SwapPage = () => {
    const dispatch = useDispatch();

    const [currency, setCurrency] = useState("sol_usdc");
    const [inputAmount, setInputAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [routes, setRoutes] = useState([]);

    useEffect(async () => {
        const bal = await dispatch(fetchWalletBalance({ network: currency }));
        setBalance(bal);
    }, [currency]);

    const handleAmountChange = async (value) => {
        setInputAmount(value);
        if (value > 0) {
            const routesData = await dispatch(fetchBestSwapRoutes({ from: currency, to: currency === "sol_usdc" ? "sol_usdt" : "sol_usdc", amount: value }));
            setRoutes(routesData);
        }

    }

    const handleSubmit = () => {
        if (inputAmount <= 0) {
            return toast.error("Please enter a valid amount");
        }
        if (balance < inputAmount) {
            return toast.error("Insufficient Balance");
        }

        dispatch(swapCoins(routes[0]));
        // setSwapModal(false);
    }

    return (
        <>
            <Box style={style} sx={resWidth}>
                <Box sx={{ p: 3 }}>
                    <Stack spacing={2}>
                        <Stack alignItems="flex-end">
                            <Chip style={{ color: "#fff", fontWeight: "bold" }} label={`Balance: ${balance} ${getBlockchainName(currency)}`}></Chip>
                        </Stack>
                        <Stack>
                            <FormLabel>You Pay</FormLabel>
                            <Stack direction={"row"} spacing={1}>
                                <FilledInput
                                    fullWidth
                                    autoComplete="on"
                                    type="number"
                                    placeholder="Amount"
                                    onChange={(e) => handleAmountChange(e.target.value)}
                                />
                                <FormControl fullWidth>
                                    <Select fullWidth size="small" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                        <MenuItem value={"sol_usdc"}> USDC </MenuItem>
                                        <MenuItem value={"sol_usdt"}> USDT </MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Stack>
                        <Stack>
                            <FormLabel>You Recieve</FormLabel>
                            <FilledInput
                                fullWidth
                                disabled
                                style={{ border: "1px solid #ccc" }}
                                value={`${inputAmount > 0 ? routes[0]?.outAmount ? routes[0]?.outAmount / 1000000 : 0 : 0} ${getBlockchainName(currency === "sol_usdc" ? ("sol_usdt") : "sol_usdc")}`}
                            />
                        </Stack>
                        <Stack>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={inputAmount <= 0 || !routes.length}
                                style={{ color: inputAmount <= 0 ? "#4d4d4d" : "", backgroundColor: inputAmount <= 0 ? "#6ba892" : "" }}
                            >
                                Swap
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default SwapPage;