import { toast } from "react-toastify";
import { post } from "services";
import {
    FETCH_ALL_USER_TXNS,
    FETCH_USER_PROFILE,
    FETCH_WALLET_BALANCE,
    SHARE_QR_LINK,
    FETCH_BLOCKCHAIN_TXN_HISTORY,
    UPDATE_USER_PROFILE,
    FETCH_BEST_SWAP_ROUTES,
    CREATE_SECURO_PAYMENT_REQUEST,
    CREATE_GUARDIAN_PAYMENT_REQUEST,
    CHECK_SECURO_USER_ID,
    CREATE_SECURO_CUSTOMER,
    SWAP_COINS,
} from "graphql";
import {
    TOGGLE_LOADING,
    LIST_ALL_USER_TXNS,
    RESET_ALL_USER_TXNS,
    LIST_USER_PROFILE,
    RESET_USER_PROFILE,
    UPDATE_WALLET_BALANCE,
    LIST_BLOCKCHAIN_TXN_HISTORY,
    RESET_BLOCKCHAIN_TXN_HISTORY,
} from "store/types";
export const listAllUserTxns = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            txns: { pageSize, pageNumber, list },
        } = getState().user;

        let apiResponse = await post("", {
            query: FETCH_ALL_USER_TXNS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_transactions: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_USER_TXNS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllUserTxns = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_USER_TXNS });
    };
};

export const listUserProfile = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_USER_PROFILE,
        });

        if (apiResponse.data.customStatus) {
            let {
                user_profile: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_USER_PROFILE,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetUserProfile = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_USER_PROFILE });
    };
};

export const fetchWalletBalance = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_WALLET_BALANCE,
            variables: requestData,
        });
        let balance = 0;

        if (apiResponse.data.customStatus) {
            let {
                list_wallet_balance: { data },
            } = apiResponse.data.data;

            balance = data?.balance;
            dispatch({
                type: UPDATE_WALLET_BALANCE,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        return balance;
    };
};

export const shareQrLink = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SHARE_QR_LINK,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("QR link shared");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listBlockchainTxnHistory = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            blockchainTxns: { pageSize, pageNumber, list, showViewMore },
        } = getState().user;

        let apiResponse = await post("", {
            query: FETCH_BLOCKCHAIN_TXN_HISTORY,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_blockchain_txn_history: { data },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_BLOCKCHAIN_TXN_HISTORY,
                payload: {
                    list: updatedList,
                    showViewMore: data.length ? true : false,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetBlockchainTxnHistory = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_BLOCKCHAIN_TXN_HISTORY });
    };
};

export const updateUserProfile = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_USER_PROFILE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Profile Details Updated");
            dispatch(resetUserProfile());
            dispatch(listUserProfile());
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const fetchBestSwapRoutes = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_BEST_SWAP_ROUTES,
            variables: requestData,
        });
        let routes = [];

        if (apiResponse.data.customStatus) {
            let {
                fetch_best_swap_routes: { data },
            } = apiResponse.data.data;

            routes = data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        return routes;
    };
};

export const swapCoins = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SWAP_COINS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Swap success");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const checkSecuroUserID = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CHECK_SECURO_USER_ID,
            variables: requestData,
        });

        let user_status = false;
        if (apiResponse.data.customStatus) {
            let {
                check_securo_user_id: { securo_status },
            } = apiResponse.data.data;

            user_status = securo_status;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        return user_status;
    };
};

export const createSecuroCustomer = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CREATE_SECURO_CUSTOMER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                create_securo_customer: { data },
            } = apiResponse.data.data;

            // dispatch({
            //     type: RAMPAY_ORDER_ID,
            //     payload: data,
            // });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        return apiResponse.data.customStatus;
    };
};

export const createSecuroPaymentRequest = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CREATE_SECURO_PAYMENT_REQUEST,
            variables: requestData,
        });

        let url = false;
        if (apiResponse.data.customStatus) {
            let {
                create_securo_pmt_request: { data },
            } = apiResponse.data.data;

            url = data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        return url;
    };
};

export const createGuardianPaymentRequest = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CREATE_GUARDIAN_PAYMENT_REQUEST,
            variables: requestData,
        });

        let url = false;
        if (apiResponse.data.customStatus) {
            let {
                create_guardian_pmt_req: { data },
            } = apiResponse.data.data;

            url = data;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
        return url;
    };
};
