import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress, Grid, Modal } from "@material-ui/core";
import { Box } from "@mui/material";

import { OutlinedButton, ContainedButton } from "components/StyledComponents/Button";
import styled from "styled-components";
import SimpleSnackbar from "components/Snackbar";
import "./admin.css";
// import bgImg from "./../assets/bg/bgimg4.jpg";
import { adminRoutes } from "routes.js";
import { logoutUser } from "store/actions";
import { history, isLoggedIn } from "utils";
import { fetchPendingReceivedPaymentReqs } from "store/actions";

// const Outer = styled.div`
//     width: 100%;
//     min-height: 100vh;
//     background: #222831;
//     font-family: "Jost", sans-serif;
// `;

const Inner = styled.div`
    padding: 20px 30px 100px;
    padding-left: 130px;
    min-height: 100vh;
    width: 100%;
    color: #cccccc;
    backdrop-filter: blur(55px);
    overflow-x: scroll;
    font-family: "Jost", sans-serif;

    @media screen and (max-width: 990px) {
        padding: 20px 20px 100px;
    }
`;

const Icon = styled.div`
    width: 100%;
    b {
        font-size: 17px;
        padding: 3px 10px;
        border-radius: 50px;
        background-color: rgb(255, 255, 255, 0.1);
    }
    #back {
        font-size: 20px;
        margin-right: 20px;
        cursor: pointer;
    }
    #coin {
        margin-right: 10px;
        font-size: 20px;
        color: #ffd700;
    }
    #logout {
        cursor: pointer;
        font-size: 20px;
        float: right;
        &:hover {
            color: white;
        }
    }
`;

const ModalBox = styled.div`
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #101427;
    color: #cccccc;
    border-radius: 10px;
    border: 1px solid;
    padding: 30px 20px;
    text-align: center;
    box-shadow: 0 3px 70px #000000;
    h3 {
        margin-bottom: 30px;
    }
    h4 {
        margin-top: -10px;
    }

    @media screen and (max-width: 500px) {
        width: 300px;
    }
`;

const Menu = styled.div`
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    position: fixed;

    padding: 10px;
    background-color: rgb(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
    box-shadow: 0 3px 20px rgb(0 0 0 / 0.2);
    font-family: "Jost", sans-serif;

    @media screen and (min-width: 992px) {
        height: 100%;
        width: 100px;
        z-index: 1;
        left: 0;
        padding-top: 20px;
    }

    i {
        font-size: 20px;
        cursor: pointer;
    }
`;

const MenuLabel = styled.div`
    font-size: 12px;
    cursor: pointer;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (min-width: 992px) {
        margin-bottom: 10px;
        font-size: 13px;
    }
`;

const Admin = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [modal, setModal] = useState(false);
    const [backButton, setBackButton] = useState(false);
    const [apiCallCount, setApiCallCount] = useState(1);
    const [stopNotification, setStopNotication] = useState(false);
    const [showSnackbar, setShowSnackBar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackBarUrl, setSnackBarUrl] = useState("");
    const pathname = window.location.pathname;

    const { isAuthenticated, loading } = useSelector(({ auth: { token }, loading }) => ({
        isAuthenticated: isLoggedIn(token),
        loading,
    }));

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // mainContent.current.scrollTop = 0;
        if (pathname === "/app/profile" || pathname === "/app/transactions" || pathname === "/app/payment-requests") {
            setBackButton(false);
        } else {
            setBackButton(true);
        }
    }, [location]);

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await dispatch(fetchPendingReceivedPaymentReqs());
            if (response.length) {
                setStopNotication(true);
                setSnackbarMsg(
                    `${response[0]?.from_uid?.name} has requested payment of ${
                        response[0]?.amount
                    } ${response[0]?.currency.toUpperCase()} to you`
                );
                setSnackBarUrl(`/app/request/${response[0].req_id}`);
                setShowSnackBar(true);
            }
            setApiCallCount(apiCallCount + 1);
        };

        const timer = setTimeout(() => {
            if (isAuthenticated && !stopNotification) {
                fetchData();
            }
        }, 10000);

        return () => clearTimeout(timer);
    }, [apiCallCount]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/app" && isAuthenticated) {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return <Redirect to={"/auth/login"} key={key} />;
            }
        });
    };

    const getTabs = (routes) => {
        return routes.map((tab, index) => {
            if (tab.visibility) {
                return (
                    <Grid item lg={12} xs={4} key={index}>
                        <div
                            onClick={() => history.push(`/app${tab.path}`)}
                            style={{
                                textAlign: "center",
                                color: `/app${tab.path}` === pathname ? "#B5EAD7" : "#cccccc",
                            }}
                        >
                            <i className={tab.icon} />
                            <MenuLabel> {tab.name} </MenuLabel>
                        </div>
                    </Grid>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Box sx={{ width: "100%" }}>
                <Inner>
                    <Icon>
                        {backButton ? <i className="fas fa-arrow-left" id="back" onClick={() => history.goBack()} /> : null}
                        <i className="fa fa-sign-out" id="logout" onClick={() => setModal(true)} />
                    </Icon>
                    <Switch>
                        {getRoutes(adminRoutes)}
                        <Redirect from="*" to="/auth/home" />
                    </Switch>
                </Inner>
            </Box>

            <Menu>
                <Grid container spacing={2}>
                    {getTabs(adminRoutes)}
                </Grid>
            </Menu>

            <Modal open={modal} onClose={() => setModal(false)}>
                <ModalBox>
                    <h3>Do you really want to logout?</h3>
                    <ContainedButton onClick={() => setModal(false)}>Cancel</ContainedButton>
                    &nbsp;&nbsp;&nbsp;
                    <OutlinedButton onClick={() => dispatch(logoutUser())}>Logout</OutlinedButton>
                </ModalBox>
            </Modal>
            <SimpleSnackbar
                open={showSnackbar}
                handleClose={() => setShowSnackBar(false)}
                message={snackbarMsg}
                openUrl={snackBarUrl}
            />
        </>
    );
};

export default Admin;
