import React from "react";
import { Card, CardContent, Grid, Button } from "@material-ui/core";
import { completePaymentRequest, resetPaymentReqDetails, listPaymentReqDetails } from "store/actions";
import { Stack } from "@mui/material";

function ConfirmModalContent({ handleModal, isAccepted, reqId, dispatch }) {
    const handleSubmit = async () => {
        handleModal();
        const response = await dispatch(completePaymentRequest({ reqId: reqId, isAccepted: isAccepted }));
        if (response) {
            dispatch(resetPaymentReqDetails());
            dispatch(listPaymentReqDetails({ reqId: reqId }));
        }
    };
    return (
        <>
            <Grid item>
                <Card style={{ borderRadius: "20px", backgroundColor: "#181529", boxShadow: "0 3px 70px #000000" }}>
                    <CardContent>
                        <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "white" }}>
                            <p>
                                {isAccepted
                                    ? "Are you sure want to complete this payment?"
                                    : "Are you sure want to reject this payment?"}
                            </p>
                        </div>
                        <br />
                        <Stack direction="row" spacing={2}>
                            <Button fullWidth variant="outlined" onClick={handleSubmit}>
                                Yes
                            </Button>
                            <Button fullWidth variant="contained" onClick={handleModal}>
                                No
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default ConfirmModalContent;
