import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Card, CardContent, Grid, Button, FilledInput, Box, CircularProgress as Loader } from "@material-ui/core";
import { Stack } from "@mui/material";

import { sendOtp, toggleLoading } from "store/actions";
import { unAuthorizedPost } from "services";
import { LOGIN_WITH_OTP, COMPLETE_PAYMENT_REQ } from "graphql";
import { toast } from "react-toastify";

function OtpModalContent({ handleModal, dispatch, email, reqId }) {
    const [isOtpSent, setIsOtpSent] = useState(false);

    const { loading } = useSelector(({ loading }) => ({
        loading,
    }));

    const handleSendOtp = async () => {
        const response = await dispatch(sendOtp({ email: email }));
        if (response) {
            setIsOtpSent(true);
        }
    };

    const handleLogin = async (otp) => {
        dispatch(toggleLoading(true));

        const apiResponse = await unAuthorizedPost("", {
            query: LOGIN_WITH_OTP,
            variables: { email: email, otp: otp },
        });

        if (apiResponse.data.customStatus) {
            let {
                login_with_otp: { token },
            } = apiResponse.data.data;

            completePayment(token);
            handleModal();
        } else {
            dispatch(toggleLoading(false));
        }
    };

    const completePayment = async (token) => {
        dispatch(toggleLoading(true));

        const apiResponse = await unAuthorizedPost(
            "",
            {
                query: COMPLETE_PAYMENT_REQ,
                variables: { reqId: reqId, isAccepted: true },
            },
            { headers: { "x-access-token": token } }
        );

        if (apiResponse.data.customStatus) {
            toast.success("Payment completed successfully");
            dispatch(toggleLoading(false));
            return window.open(`/payment/${reqId}`, "_self");
        } else {
            return dispatch(toggleLoading(false));
        }
    };

    return (
        <>
            <Grid item>
                <Card style={{ borderRadius: "20px", backgroundColor: "#181529", boxShadow: "0 3px 70px #000000" }}>
                    <CardContent>
                        {!isOtpSent ? (
                            <>
                                <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "white" }}>
                                    <p>
                                        Upon clicking on the send OTP button, a confirmation OTP will be sent to your email{" "}
                                        {email}
                                    </p>
                                </div>
                                <br />
                                <Stack direction="row" spacing={2}>
                                    <Button fullWidth variant="contained" onClick={handleSendOtp}>
                                        {loading ? <Loader color="secondary" size={20} /> : "Send OTP"}
                                    </Button>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        onClick={handleModal}
                                        disabled={loading}
                                        style={{ color: loading ? "grey" : "" }}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </>
                        ) : (
                            <>
                                <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "white" }}>
                                    <p>OTP confirmation received at {email} must be entered</p>
                                </div>
                                <br />
                                <Formik
                                    initialValues={{
                                        otp: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                        otp: Yup.string().required("OTP is required"),
                                    })}
                                    onSubmit={(value) => {
                                        handleLogin(value.otp);
                                    }}
                                >
                                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                        <form onSubmit={handleSubmit}>
                                            <FilledInput
                                                autoComplete="off"
                                                type="text"
                                                fullWidth
                                                placeholder="Enter OTP"
                                                name="otp"
                                                defaultValue={values.otp}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                // startAdornment={
                                                //     <InputAdornment position="start">
                                                //         <Email />
                                                //     </InputAdornment>
                                                // }
                                                required={true}
                                                error={Boolean(touched.otp && errors.otp)}
                                                disabled={loading}
                                            />
                                            {Boolean(touched.otp && errors.otp) ? (
                                                <div
                                                    style={{
                                                        display: "block",
                                                        marginLeft: "10px",
                                                        color: "red",
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    {errors.otp}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <br />

                                            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                                <Button
                                                    id="signin-btn"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                    disabled={values.otp === "" || loading}
                                                    style={{ color: values.otp === "" || loading ? "grey" : "" }}
                                                >
                                                    {loading ? <Loader color="secondary" size={20} /> : "Continue"}
                                                </Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default OtpModalContent;
