export const securoCryptoCurrencies = [
    {
        code: "SOL",
        name: "Solana",
        network: "solana",
        display_chain: "Solana chain",
    },
    {
        code: "USDC",
        name: "USDC",
        network: "solana",
        display_chain: "Solana chain",
    },
    {
        code: "USDT",
        name: "USDT",
        network: "solana",
        display_chain: "Solana chain",
    },
];
