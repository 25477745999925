export const guardarianFiatCurrencies = [
    {
        id: "4881817401",
        currency_type: "FIAT",
        ticker: "EUR",
        name: "Euro",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: "",
        payment_methods: [
            {
                type: "SEPA_2",
                deposit_enabled: true,
                withdrawal_enabled: true,
            },
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
            {
                type: "SEPA_1",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "300",
        networks: [
            {
                name: "Euro",
                network: "EUR",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "SEPA_2",
                        deposit_enabled: true,
                        withdrawal_enabled: true,
                    },
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                    {
                        type: "SEPA_1",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5885505340",
        currency_type: "FIAT",
        ticker: "USD",
        name: "United States dollar",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "SEPA_2",
                deposit_enabled: true,
                withdrawal_enabled: true,
            },
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "350",
        networks: [
            {
                name: "United States dollar",
                network: "USD",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "SEPA_2",
                        deposit_enabled: true,
                        withdrawal_enabled: true,
                    },
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4551860334",
        currency_type: "FIAT",
        ticker: "GBP",
        name: "British Pound Sterling",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "SEPA_2",
                deposit_enabled: true,
                withdrawal_enabled: true,
            },
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "300",
        networks: [
            {
                name: "British Pound Sterling",
                network: "GBP",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "SEPA_2",
                        deposit_enabled: true,
                        withdrawal_enabled: true,
                    },
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    // {
    //     id: "4755719855",
    //     currency_type: "FIAT",
    //     ticker: "TRY",
    //     name: "Turkish lira",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: "",
    //     default_exchange_value: "3500",
    //     networks: [
    //         {
    //             name: "Turkish lira",
    //             network: "TRY",
    //             block_explorer_url_mask: "",
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: "4972205417",
    //     currency_type: "FIAT",
    //     ticker: "DKK",
    //     name: "Danish Krone",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "2500",
    //     networks: [
    //         {
    //             name: "Danish Krone",
    //             network: "DKK",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: "5697097837",
    //     currency_type: "FIAT",
    //     ticker: "SEK",
    //     name: "Swedish Krona",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "3000",
    //     networks: [
    //         {
    //             name: "Swedish Krona",
    //             network: "SEK",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: "4607226210",
    //     currency_type: "FIAT",
    //     ticker: "ZAR",
    //     name: "South African rand",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "5000",
    //     networks: [
    //         {
    //             name: "South African rand",
    //             network: "ZAR",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: "5222693168",
    //     currency_type: "FIAT",
    //     ticker: "CHF",
    //     name: "Swiss Franc",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "350",
    //     networks: [
    //         {
    //             name: "Swiss Franc",
    //             network: "CHF",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: "5087736642",
    //     currency_type: "FIAT",
    //     ticker: "PLN",
    //     name: "Polish Zloty ",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "1500",
    //     networks: [
    //         {
    //             name: "Polish Zloty ",
    //             network: "PLN",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        id: "6058389003",
        currency_type: "FIAT",
        ticker: "AUD",
        name: "Australian Dollar",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "500",
        networks: [
            {
                name: "Australian Dollar",
                network: "AUD",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5891859399",
        currency_type: "FIAT",
        ticker: "CAD",
        name: "Canadian Dollar",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "450",
        networks: [
            {
                name: "Canadian Dollar",
                network: "CAD",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    // {
    //     id: "4949212060",
    //     currency_type: "FIAT",
    //     ticker: "ILS",
    //     name: "Israeli New Shekel",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "1000",
    //     networks: [
    //         {
    //             name: "Israeli New Shekel",
    //             network: "ILS",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        id: "4507286639",
        currency_type: "FIAT",
        ticker: "AED",
        name: "United Arab Emirates dirham",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "1500",
        networks: [
            {
                name: "United Arab Emirates dirham",
                network: "AED",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    // {
    //     id: "6218100135",
    //     currency_type: "FIAT",
    //     ticker: "KRW",
    //     name: "South Korean won",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: null,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "100000",
    //     networks: [
    //         {
    //             name: "South Korean won",
    //             network: "KRW",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        id: "5297838483",
        currency_type: "FIAT",
        ticker: "JPY",
        name: "Japanese Yen",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "40000",
        networks: [
            {
                name: "Japanese Yen",
                network: "JPY",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5114740132",
        currency_type: "FIAT",
        ticker: "NZD",
        name: "New Zealand Dollar ",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "500",
        networks: [
            {
                name: "New Zealand Dollar ",
                network: "NZD",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4772696651",
        currency_type: "FIAT",
        ticker: "NGN",
        name: "Nigerian Naira",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "100000",
        networks: [
            {
                name: "Nigerian Naira",
                network: "NGN",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5803188808",
        currency_type: "FIAT",
        ticker: "COP",
        name: "Colombian Peso",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: false,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "1000000",
        networks: [
            {
                name: "Colombian Peso",
                network: "COP",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4777541695",
        currency_type: "FIAT",
        ticker: "SGD",
        name: "Singapore Dollar ",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "600",
        networks: [
            {
                name: "Singapore Dollar ",
                network: "SGD",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5583977848",
        currency_type: "FIAT",
        ticker: "BRL",
        name: "Brazilian Real",
        enabled: true,
        has_external_id: false,
        is_featured: false,
        is_stable: false,
        is_available: null,
        logo_url: "https://changenow.io/images/sprite/currencies/brl.svg",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "2000",
        networks: [
            {
                name: "Brazilian Real",
                network: "BRL",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5709815551",
        currency_type: "FIAT",
        ticker: "INR",
        name: "Indian Rupee",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "10000",
        networks: [
            {
                name: "Indian Rupee",
                network: "INR",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5239857822",
        currency_type: "FIAT",
        ticker: "OMR",
        name: "Omani rial",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "150",
        networks: [
            {
                name: "Omani rial",
                network: "OMR",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    // {
    //     id: "4831152434",
    //     currency_type: "FIAT",
    //     ticker: "NOK",
    //     name: "Norwegian Krone",
    //     enabled: true,
    //     has_external_id: false,
    //     is_featured: null,
    //     is_stable: null,
    //     is_available: false,
    //     logo_url: null,
    //     payment_methods: [
    //         {
    //             type: "VISA_MC5",
    //             deposit_enabled: true,
    //             withdrawal_enabled: false,
    //         },
    //     ],
    //     block_explorer_url_mask: null,
    //     default_exchange_value: "3000",
    //     networks: [
    //         {
    //             name: "Norwegian Krone",
    //             network: "NOK",
    //             block_explorer_url_mask: null,
    //             token_contract: null,
    //             payment_methods: [
    //                 {
    //                     type: "VISA_MC5",
    //                     deposit_enabled: true,
    //                     withdrawal_enabled: false,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    {
        id: "4430685946",
        currency_type: "FIAT",
        ticker: "BGN",
        name: "Bulgarian Lev",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "600",
        networks: [
            {
                name: "Bulgarian Lev",
                network: "BGN",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4670026008",
        currency_type: "FIAT",
        ticker: "HRK",
        name: "Croatian Kuna",
        enabled: true,
        has_external_id: false,
        is_featured: false,
        is_stable: false,
        is_available: null,
        logo_url: "https://changenow.io/images/sprite/currencies/hrk.svg",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "2500",
        networks: [
            {
                name: "Croatian Kuna",
                network: "HRK",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5387694786",
        currency_type: "FIAT",
        ticker: "BOB",
        name: "Bolivian Boliviano",
        enabled: true,
        has_external_id: false,
        is_featured: false,
        is_stable: false,
        is_available: null,
        logo_url: "https://changenow.io/images/sprite/currencies/bob.svg",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "2600",
        networks: [
            {
                name: "Bolivian Boliviano",
                network: "BOB",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5146336873",
        currency_type: "FIAT",
        ticker: "CLP",
        name: "Chilean Peso",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "300000",
        networks: [
            {
                name: "Chilean Peso",
                network: "CLP",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5572908793",
        currency_type: "FIAT",
        ticker: "BHD",
        name: "Bahraini Dinar",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "150",
        networks: [
            {
                name: "Bahraini Dinar",
                network: "BHD",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5781424039",
        currency_type: "FIAT",
        ticker: "MXN",
        name: "Mexican Peso",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "7500",
        networks: [
            {
                name: "Mexican Peso",
                network: "MXN",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5734198742",
        currency_type: "FIAT",
        ticker: "TWD",
        name: "New Taiwan dollar",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: "",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "11000",
        networks: [
            {
                name: "New Taiwan dollar",
                network: "TWD",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "6260164995",
        currency_type: "FIAT",
        ticker: "ARS",
        name: "Argentine Peso",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "35000",
        networks: [
            {
                name: "Argentine Peso",
                network: "ARS",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "6331424085",
        currency_type: "FIAT",
        ticker: "PHP",
        name: "Philippine peso",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: "",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "19000",
        networks: [
            {
                name: "Philippine peso",
                network: "PHP",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4511349092",
        currency_type: "FIAT",
        ticker: "RON",
        name: "Romanian Leu ",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "1500",
        networks: [
            {
                name: "Romanian Leu ",
                network: "RON",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5069141924",
        currency_type: "FIAT",
        ticker: "MYR",
        name: "Malaysian Ringgit",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: "",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "1600",
        networks: [
            {
                name: "Malaysian Ringgit",
                network: "MYR",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4454736336",
        currency_type: "FIAT",
        ticker: "THB",
        name: "Thai Baht",
        enabled: true,
        has_external_id: false,
        is_featured: false,
        is_stable: false,
        is_available: null,
        logo_url: "https://changenow.io/images/sprite/currencies/thb.svg",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "12500",
        networks: [
            {
                name: "Thai Baht",
                network: "THB",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4443847005",
        currency_type: "FIAT",
        ticker: "DOP",
        name: " Dominican Peso",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "25000",
        networks: [
            {
                name: " Dominican Peso",
                network: "DOP",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5303272731",
        currency_type: "FIAT",
        ticker: "HKD",
        name: "Hong Kong Dollar ",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "3000",
        networks: [
            {
                name: "Hong Kong Dollar ",
                network: "HKD",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5175110992",
        currency_type: "FIAT",
        ticker: "PEN",
        name: "Sol",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "1440",
        networks: [
            {
                name: "Sol",
                network: "PEN",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5171293883",
        currency_type: "FIAT",
        ticker: "BWP",
        name: "Botswanan Pula",
        enabled: true,
        has_external_id: false,
        is_featured: false,
        is_stable: false,
        is_available: null,
        logo_url: "https://changenow.io/images/sprite/currencies/bwp.svg",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "4000",
        networks: [
            {
                name: "Botswanan Pula",
                network: "BWP",
                block_explorer_url_mask: "",
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4683391983",
        currency_type: "FIAT",
        ticker: "BDT",
        name: "Bangladeshi Taka",
        enabled: true,
        has_external_id: false,
        is_featured: false,
        is_stable: false,
        is_available: null,
        logo_url: "https://changenow.io/images/sprite/currencies/bdt.svg",
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "10000",
        networks: [
            {
                name: "Bangladeshi Taka",
                network: "BDT",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "5628951363",
        currency_type: "FIAT",
        ticker: "VND",
        name: "Vietnamese Dong",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "10000000",
        networks: [
            {
                name: "Vietnamese Dong",
                network: "VND",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "4716492338",
        currency_type: "FIAT",
        ticker: "IDR",
        name: "IDR",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: "",
        default_exchange_value: "1000000",
        networks: [
            {
                name: "Indonesian Rupiah",
                network: "IDR",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
    {
        id: "6125184366",
        currency_type: "FIAT",
        ticker: "CZK",
        name: "Czech Koruna",
        enabled: true,
        has_external_id: false,
        is_featured: null,
        is_stable: null,
        is_available: null,
        logo_url: null,
        payment_methods: [
            {
                type: "VISA_MC5",
                deposit_enabled: true,
                withdrawal_enabled: false,
            },
        ],
        block_explorer_url_mask: null,
        default_exchange_value: "8000",
        networks: [
            {
                name: "Czech Koruna",
                network: "CZK",
                block_explorer_url_mask: null,
                token_contract: null,
                payment_methods: [
                    {
                        type: "VISA_MC5",
                        deposit_enabled: true,
                        withdrawal_enabled: false,
                    },
                ],
            },
        ],
    },
];
