import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { checkSecuroUserID } from "store/actions";
import BuyingPage from "./buyingPage";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    backgroundColor: "#181529",
    color: "#fff",
};
const resWidth = {
    width: {
        lg: "430px",
        md: "430px",
        sm: "430px",
        xs: "90%",
    },
};

const BuyPage = () => {
    const dispatch = useDispatch();
    const [userStatus, setUserStatus] = useState(false);

    useEffect(async () => {
        let securo_status = await dispatch(checkSecuroUserID());
        setUserStatus(securo_status);
    }, []);

    return (
        <>
            <Box style={style} sx={resWidth}>
                <BuyingPage dispatch={dispatch} />
            </Box>
        </>
    );
};

export default BuyPage;
